.App {
  text-align: center;
  font-family: monospace;
}

.App-logo {
  pointer-events: none;
  text-transform: uppercase;
  font-size: calc(30px + 2vmin);
  font-weight: 700;
  margin: 20px 20px;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.dark-title {
  color: #212123 !important;
}

.section1{
  max-height: 25vh;
}

.section1-text {
  text-align: initial;
  font-size: calc(32px + 1vw);;
  position: absolute;
  top: calc(20%);
  left: 10%;
  width: 60%;
}

.section2 {
  height: calc(75vh - 100px);
  background: url('/src/images/wallpaper5.jpg') no-repeat;
  background-position: left top;
  background-size: 100%;
}

.section3{
  padding: 4rem;
}

.section4{
  padding: 4rem;
  background: url('/src/images/wallpaper8.jpg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  height: fit-content;
}

.container-cards {
  display: inline-block;
  align-items: center;
}

.hr-line {
  margin: 20px 0;
  padding: 2px;
  width: 70%;
  border: none;
  border-radius: 3px;
  background: linear-gradient(to right, #151957, transparent);
}

.hr-line-title-green {
  display: inline-flex;
  margin: 20px 0;
	padding: 2px;
  width: 40%;
	border: none;
	border-top: 1px solid;
  border-radius: 3px;
  color: #151957;
}

.h1-title {
  font-weight: 600;
  font-size: calc(1rem + 3vw);
}

.hr-line-title {
  display: inline-flex;
  margin: 20px 0;
	padding: 2px;
  width: 40%;
	border: none;
	border-top: 1px solid;
  border-radius: 3px;
  color: #151957;
}

.hr-line-steps {
  display: inline-flex;
  margin: 20px 0;
	padding: 5px;
  width: 85%;
	border: none;
  border-radius: 10px;
  background: linear-gradient(to right, #151957, transparent);
}

.card-body-custom {
  text-align: initial;
  padding-left: 30px;
}

.cards-row {
  display: inline-flex;
}

.home-icon g {
  fill: rgb(14, 14, 15);
 }

 .home-icon path {
  stroke-width: 10px;
 }

 .home-icon{
  fill: rgb(12, 12, 14);
 }

 .card-text {
    font-size: calc(22px);
 }

 .card-title {
   font-weight: 600;
   font-size: calc(28px);
 }

 .numberCircle {
  border-radius: 50%;
  width: 16%;
  height: 16%;
  padding: 8px;
  margin-right: 20px;
  background: #fff;
  border: 2px solid #f7faf5;
  color: #666;
  text-align: center;

  font: 32px Arial, sans-serif;
}

.numberCircle2 {
  border-radius: 50%;
  width: calc(54px + 2vh);
  height: calc(54px + 2vh);
  padding: calc(15px);
  background: #fff;
  border: 3px solid #151957;
  color: #151957;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.card-header-custom {
  display: inline-flex;
  align-items: flex-end;
}

.display-evenly {
  display: flex;
  justify-content: space-evenly;
}

.contact-div {
  display: inline-flex;
  align-items: center;
}

.steps {
  display: flex;
  justify-content: space-around;
}

.explain-text {
  color: #6c757d;
}

.section5 {
  padding: 4rem;
  height: fit-content;
  background: url('/src/images/wallpaper6.jpg') no-repeat;
  background-position: left top;
  background-size: 100%;
}

.adv-icon {
  width: fit-content;
}

.card-text-why {
  padding: 3rem;
  font-size: calc(18px + 0.3vh);
}

.card-header-text {
  font-size: calc(24px + 0.3vh);
}

a:hover {
  background-color: rgb(200, 205, 201);
}

@media (max-width: 1000px) { 
  .card-text-why {
    padding: 0;
    font-size: calc(12px + 0.3vh);
  }
  .card-header-text {
    font-size: calc(16px + 0.3vh);
  }

  .section1-text {
    font-size: calc(18px + 1vw);;
    width: 80%;
  }

  .card-title {
    font-size: 16px;
  }

  .explain-text {
    font-size: 12px;
  }
}